import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import DaySelector from './order/DaySelector';
import { Button } from './Home/v2/StyledComponents';
import { Wrapper, Block, Logo, Header2, Title2, Dates, Center, Title3, Highlight }
  from './styledComponents';
import ProgressBar from './order/ProgressBar';
import LogoImg from '../assets/logo1-128.png';
import { trackEvent } from '../services/analytics';
import featureSwitch from '../services/featureSwitch';

const Step4 = ({ selectDay, isValid, goNext, selectedDays }) => (
  <Wrapper>
    <Block>
      <Header2>
        <Link
          to={'/'} style={{ flex: 1, textAlign: 'center' }}
          onClick={() => trackEvent('click', 'home_step4')}
        >
          <Logo src={LogoImg} />
        </Link>
        <ProgressBar step={3} />
        <Title2>
          <Highlight>
            {featureSwitch('order_thirdPersonLabels') ? 'les disponibilités de mon client' :
              'mes disponibilités' }
          </Highlight>
        </Title2>
      </Header2>
      <Dates>
        {featureSwitch('step4_showCurtainWarning') &&
          <div>
            Rappeler à votre client la pose de 48h et le repassage.<br />
            Prise de rdv à +2 jours.
          </div>
        }
        <DaySelector onChange={selectDay} selectedDays={selectedDays} />
        <Title3>livraison 3 à 5 jours après</Title3>
      </Dates>
      <Center>
        <Button isNotValid={!isValid} onClick={goNext}>{'suivant'}</Button>
      </Center>
    </Block>
  </Wrapper>
);

Step4.propTypes = {
  selectDay: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  goNext: PropTypes.func.isRequired,
  selectedDays: PropTypes.shape({}),
};

Step4.defaultProps = {
  isValid: false,
  selectedDays: {},
};

export default Step4;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { ViewPager, Frame, Track, View } from 'react-view-pager';
import styled from 'styled-components';

import isMobile from '../../services/isMobile';
import Day from './Day';
import momentFr from '../../config/moment.fr';
import { margins, mobileThresholdPixels } from '../styledComponents';

import arrowLeft from '../../assets/arrow.left.navy.svg';
import arrowRight from '../../assets/arrow.right.navy.svg';

moment.updateLocale('fr', momentFr);
moment.tz.setDefault('Europe/Paris');

const frameStyle = {
  width: '100%',
  position: 'relative',
  outline: 'none',
};

const viewPagerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const ControlContainer = styled.div``;

const ControlImg = styled.img`
  z-index: 2;
  cursor: pointer;
  width: 30px;
  margin: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 15px;
  }
`;

const isSelected = (selectedDays, day) => {
  const slots = selectedDays[day.format()];
  return slots && slots.length > 0;
};

class SlotSelector extends React.Component {
  constructor() {
    super();
    this.selectDay = this.selectDay.bind(this);
    const days = [];
    let dayIt = moment().isBefore('2018-08-27', 'day') ? moment('2018-08-26') : moment();
    dayIt = dayIt.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    while (days.length < 60) {
      dayIt = dayIt.add(1, 'days');
      days.push(moment(dayIt));
    }
    this.state = { days };
  }

  selectDay(day) {
    this.props.onChange(day);
  }

  render() {
    return (
      <ViewPager tag="main" style={viewPagerStyle}>
        <ControlContainer>
          <ControlImg
            alt="Jours précédents"
            src={arrowLeft} onClick={() => this.track.prev()}
          />
        </ControlContainer>
        <Frame className="frame" style={frameStyle}>
          <Track
            ref={(c) => { this.track = c; }}
            viewsToShow={isMobile() ? 4 : 6}
            viewsToMove={isMobile() ? 2 : 3}
            className="track"
            contain
          >
            {this.state.days.map(day =>
              <View className="view" key={day.format()}>
                <Day
                  day={day}
                  isSelected={isSelected(this.props.selectedDays, day)}
                  selectDay={this.selectDay}
                />
              </View>)}
          </Track>
        </Frame>
        <ControlContainer right>
          <ControlImg
            alt="Jours suivants"
            src={arrowRight} onClick={() => this.track.next()}
          />
        </ControlContainer>
      </ViewPager>
    );
  }
}

SlotSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedDays: PropTypes.shape({}),
};

SlotSelector.defaultProps = {
  selectedDays: {},
};

export default SlotSelector;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ViewPager, Frame, Track, View } from 'react-view-pager';

import isMobile from '../../services/isMobile';
import Slot from './Slot';
import { margins, mobileThresholdPixels } from '../styledComponents';

import arrowLeft from '../../assets/arrow.left.navy.svg';
import arrowRight from '../../assets/arrow.right.navy.svg';

const frameStyle = {
  width: '100%',
  position: 'relative',
  outline: 'none',
};

const viewPagerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const ControlContainer = styled.div``;

const ControlImg = styled.img`
  z-index: 2;
  cursor: pointer;
  width: 30px;
  margin: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s};
    width: 15px;
  }
`;

function createSlot(hours, is30 = false) {
  return {
    hours,
    minutes: is30 ? 30 : 0,
    string1: is30 ? `${hours}.30` : `${hours}.00`,
    string2: is30 ? `${hours + 1}.00` : `${hours}.30`,
  };
}

function createSlots(beginHour, endHour, removeLastOne = true) {
  const slots = [];
  let slotHour;
  for (slotHour = beginHour; slotHour <= endHour; slotHour += 1) {
    slots.push(createSlot(slotHour));
    slots.push(createSlot(slotHour, true));
  }
  if (removeLastOne) slots.pop();
  return slots;
}

const eveningSlots = createSlots(17, 21);
const allDaySlots = createSlots(9, 21);

const isSelected = (slots, slot) =>
  slots.reduce((selected, slotIt) =>
    selected || (slotIt.hours === slot.hours && slotIt.minutes === slot.minutes),
  false);

class SlotSelector extends React.Component {
  render() {
    const { showAllSlots, selectedSlots, onChange } = this.props;
    const activeSlotsRange = showAllSlots ? allDaySlots : eveningSlots;
    return (
      <ViewPager tag="main" style={viewPagerStyle}>
        <ControlContainer>
          <ControlImg
            alt="Créneaux précédents"
            src={arrowLeft} onClick={() => this.track.prev()}
          />
        </ControlContainer>
        <Frame className="frame" style={frameStyle}>
          <Track
            ref={(c) => { this.track = c; }}
            viewsToShow={isMobile() ? 4 : 9}
            viewsToMove={isMobile() ? 2 : 9}
            className="track"
          >
            {activeSlotsRange.map(slot =>
              <View className="view" key={slot.string1}>
                <Slot
                  isSelected={isSelected(selectedSlots, slot)}
                  onClick={() => onChange(slot.hours, slot.minutes)}
                  slot={slot}
                />
              </View>)}
          </Track>
        </Frame>
        <ControlContainer right>
          <ControlImg
            alt="Créneaux suivants"
            src={arrowRight} onClick={() => this.track.next()}
          />
        </ControlContainer>
      </ViewPager>
    );
  }
}

SlotSelector.propTypes = {
  onChange: PropTypes.func,
  showAllSlots: PropTypes.bool,
  selectedSlots: PropTypes.arrayOf(PropTypes.shape({})),
};

SlotSelector.defaultProps = {
  onChange() {},
  showAllSlots: false,
  selectedSlots: [],
};

export default SlotSelector;

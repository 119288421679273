import React from 'react';
import RdvContainer from '../containers/RdvContainer';
import routes from '../../../Routes';
import Layout from '../../../layouts/index';

const nextStep = routes.FastVariantFabrics.url;
const Step2Rdv = () => (
  <Layout slug="FastVariantRdv">
    <RdvContainer nextStep={nextStep} />
  </Layout>
);
export default Step2Rdv;

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import moment from 'moment-timezone';

import OrderContainer from '../../common/OrderContainer';
import Step4 from '../../../components/Step4v1';
import Modal from '../../../components/order/SlotModal';
import { trackEvent } from '../../../services/analytics';

const formatSlots = (slots) => {
  const formattedSlots = [];
  Object.keys(slots).forEach((slotDay) => {
    slots[slotDay].forEach((slot) => {
      const day = moment(slotDay);
      const begin = day.set({ hours: slot.hours, minutes: slot.minutes });
      const end = begin.clone().add(30, 'minute');
      formattedSlots.push({ begin, end });
    });
  });
  return formattedSlots.sort((slotA, slotB) => slotA.begin.valueOf() - slotB.begin.valueOf());
};

class RdvContainer extends React.Component {
  constructor() {
    super();
    this.state = { slots: {}, showAllSlots: false };
    this.goNext = this.goNext.bind(this);
    this.selectDay = this.selectDay.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    if (window.location.hostname !== 'localhost') {
      window.onbeforeunload = function () {
        return true;
      };
    }
  }

  goNext() {
    if (this.isValid()) {
      this.props.dispatch({ type: 'ORDER.SET_SLOTS', slots: formatSlots(this.state.slots) });
      navigate(this.props.nextStep);
    } else {
      trackEvent('erreur', 'pas-creneau');
    }
  }

  selectDay(selectedDay) {
    this.setState({
      selectedDay: selectedDay.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      showAllSlots: !moment().add(1, 'day').isSame(selectedDay, 'day'),
    });
  }

  validateDay(newSlots) {
    const { selectedDay, slots } = this.state;
    this.setState({
      selectedDay: null,
      slots: { ...slots, [selectedDay.format()]: newSlots } });
  }

  isValid() {
    return !!Object.values(this.state.slots).reduce((nbSlots, slots) => nbSlots + slots.length, 0);
  }

  render() {
    const { selectedDay, slots } = this.state;
    return (
      <OrderContainer>
        {this.state.selectedDay &&
          <Modal
            closeModal={() => this.setState({ selectedDay: null })}
            submit={newSlots => this.validateDay(newSlots)}
            day={selectedDay}
            showAllSlots={this.state.showAllSlots}
            slots={slots[selectedDay.format()]}
          />
        }
        <Step4
          isValid={this.isValid()}
          goNext={this.goNext}
          selectDay={this.selectDay}
          selectedDays={slots}
        />
      </OrderContainer>
    );
  }
}

RdvContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  nextStep: PropTypes.string.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(RdvContainer);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, margins, mobileThresholdPixels } from '../styledComponents';
import { Title1, Button } from '../Home/v2/StyledComponents';
import SlotSelector from './SlotSelector';
import featureSwitch from '../../services/featureSwitch';

const Background = styled.div`
  background-color: ${colors.navyTransparent};
  min-height: 100vh;
  width: 100vw;
  z-index: 4;
  top: 0;
  position:fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  margin: ${margins.xl} 0px;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1180px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.xl} ${margins.s};
    width: 100%;
  }
`;

const Container = styled.div`
  background-color: ${colors.white};
  padding: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: ${margins.s};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${margins.m};
`;

const hasClickedInsideModale = (evt) => {
  const flyoutElement = document.getElementById('modal');
  let targetElement = evt.target; // clicked element

  do {
    if (targetElement === flyoutElement) {
      return true;
    }
    // Go up the DOM
    targetElement = targetElement.parentNode;
  } while (targetElement);

  return false;
};

class SlotModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedSlots: props.slots || [] };
  }

  toggleSlot(hours, minutes) {
    const slotIndex = this.state.selectedSlots
      .findIndex(slot => slot.hours === hours && slot.minutes === minutes);
    if (slotIndex >= 0) {
      const selectedSlots = [...this.state.selectedSlots];
      selectedSlots.splice(slotIndex, 1);
      this.setState({ selectedSlots });
    } else {
      this.setState({ selectedSlots: [...this.state.selectedSlots, { hours, minutes }] });
    }
  }

  render() {
    const { closeModal, submit, day, showAllSlots } = this.props;
    return (
      <Background onClick={e => !hasClickedInsideModale(e) && closeModal()}>
        <Modal id="modal">
          <Container>
            <Title1>
              {featureSwitch('order_thirdPersonLabels') ?
                `Les disponibilités de mon client pour le ${day.format('D MMMM')}` :
                `Mes disponibilités pour le ${day.format('D MMMM')}`
              }
            </Title1>
            <SlotSelector
              onChange={(hours, minutes) => this.toggleSlot(hours, minutes)}
              selectedSlots={this.state.selectedSlots}
              showAllSlots={showAllSlots}
            />
            <Row>
              <Button onClick={() => submit(this.state.selectedSlots)}>
                Valider
              </Button>
            </Row>
          </Container>
        </Modal>
      </Background>
    );
  }
}

SlotModal.propTypes = {
  day: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func,
  submit: PropTypes.func,
  slots: PropTypes.arrayOf(PropTypes.shape({})),
  showAllSlots: PropTypes.bool,
};

SlotModal.defaultProps = {
  closeModal() {},
  submit() {},
  slots: [],
  showAllSlots: false,
};

export default SlotModal;
